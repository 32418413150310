<template>
    <b-form @submit.prevent>
        <b-row class="mb-3">
            <b-col class="bg-soft-light border-2 border-end border-light pt-md-3 col-12 col-md-6 col-lg-4">
                <b-row class="mx-0">
                    <b-col cols="12" class="mb-3 px-0 px-md-1">
                        <b-form-group
                            id="settings-sms-coding"
                        >
                            <div class="form-check form-switch form-switch-md custom-checkbox mb-2">
                                <label class="form-check-label" for="settings-sms-utf8">{{ $t('profile.utf8_sms_sending') }}</label>
                                <input type="checkbox" id="settings-sms-utf8" name="settings-sms-utf8" class="form-check-input" v-model="model.is_sms_utf8" />
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" class="mb-3 px-0 px-md-1">
                        <b-form-group :label="$t('sms.type_of_sending')">
                            <b-form-radio-group
                                id="sending-type"
                                v-model="toList"
                                :options="listOptions"
                                name="sending-type-options"
                            ></b-form-radio-group>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" class="mb-3 px-0 px-md-1">
                        <b-form-group :label="$t('sms.type_of_number')">
                            <b-form-radio-group
                                id="phone-number-type"
                                v-model="detailed"
                                :options="typeOptions"
                                name="phone-number-type-options"
                            ></b-form-radio-group>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" v-if="false">
                        <div class="d-inline-flex align-items-center border-end border-1 border-light pe-3">
                            <label class="form-check-label me-2" for="to-list"><i class="fas fa-user"></i></label>
                            <div class="form-check form-switch form-switch-md">
                                <input type="checkbox" id="to-list" class="form-check-input" v-model="toList" />
                            </div>
                            <label class="form-check-label" for="to-list"><i class="fas fa-users"></i></label>
                        </div>
                        <div class="d-inline-flex align-items-center ps-3">
                            <label class="form-check-label me-2" for="type"><i class="fas fa-minus"></i></label>
                            <div class="form-check form-switch form-switch-md">
                                <input type="checkbox" id="type" class="form-check-input" v-model="detailed" />
                            </div>
                            <label class="form-check-label" for="type"><i class="fas fa-ellipsis-h"></i></label>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="mx-0  mb-3">
                    <b-col v-if="!detailed" cols="12" class="px-0 px-md-1">
                        <b-form-group
                            id="phone-number"
                            class="w-100"
                            :label="$t('sms.phone_number')"
                            label-for="phone-number-input"
                            label-cols="12"
                            content-cols="12"
                            :state="phoneNumberState"
                            :invalid-feedback="error.phone_number"
                        >
                            <vue-tel-input id="phone-number-input" v-model="model.phone_number" :defaultCountry="defaultLocale" mode="international" validCharactersOnly :inputOptions="{placeholder: '+36 30 000 0000'}"></vue-tel-input>
                        </b-form-group>
                    </b-col>
                    <template v-if="detailed">
                        <b-col cols="12" md="4" xl="3" class="px-md-1">
                            <b-form-group
                                id="country-code"
                                :label="$t('sms.country_code')"
                                label-for="country-code-input"
                                label-class="text-overflow-ellipsis"
                                label-cols="12"
                                content-cols="12"
                            >
                                <b-form-input size="sm" id="country-code-input" required pattern="\+?[0-9]{1,4}" v-model="model.country_code" :placeholder="$t('sms.placeholders.country_code')"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4" xl="3" class="px-md-1">
                            <b-form-group
                                id="area-code"
                                :label="$t('sms.area_code')"
                                label-for="area-code-input"
                                label-class="text-overflow-ellipsis"
                                label-cols="12"
                                content-cols="12"
                            >
                                <b-form-input size="sm" id="area-code-input" required pattern="[0-9]{1,2}" v-model="model.area_code" :placeholder="$t('sms.placeholders.area_code')"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4" xl="6" class="px-md-1">
                            <b-form-group
                                id="number"
                                :label="$t('sms.number')"
                                label-for="number-input"
                                label-class="text-overflow-ellipsis"
                                label-cols="12"
                                content-cols="12"
                            >
                                <b-form-input size="sm" id="number-input" required pattern="[0-9]{7}" v-model="model.number" :placeholder="$t('sms.placeholders.number')"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <div :class="{'d-block': !countryCodeState || !areaCodeState || !numberState}" class="invalid-feedback">{{ error.country_code }} {{ error.area_code }} {{ error.number }}</div>
                        </b-col>
                    </template>
                </b-row>
                <b-row class="m-0">
                    <b-col v-if="!detailed" cols="12" class="px-0 px-md-1">
                        <b-form-group
                            id="send-date"
                            class="w-100"
                            :label="$t('sms.send_date')"
                            label-for="send-date-input"
                            label-cols="12"
                            content-cols="12"
                            :state="sendDateState"
                            :invalid-feedback="error.send_date"
                        >
                            <b-form-input id="send-date-input" type="datetime-local" required v-model="model.send_date"></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-col>
            <b-col class="col-12 col-md-6 col-lg-8">
                <b-form-group
                    id="message"
                    :label="$t('sms.message')"
                    label-for="message-input"
                    label-cols="12"
                    content-cols="12"
                    :state="messageState"
                    :invalid-feedback="error.message"
                >
                    <template #label>
                        <label class="col-12 col-form-label" for="message-input">
                            <span class="me-3">{{ $t('sms.message') }}</span>
                            <small class="text-muted" v-if="model.message">{{ $t('sms.message_length', { sms: sms, chars: chars }) }}</small>
                        </label>
                    </template>
                    <div class="position-relative rounded overflow-hidden">
                        <b-form-textarea ref="message" id="message-input" :rows="14" v-model="model.message" :placeholder="$t('sms.placeholders.message')"></b-form-textarea>
                        <div class="bg-light position-absolute bottom-0 w-100 border p-1">
                            <b-button :disabled="disabledLink" size="sm" variant="primary" :title="$t('sms.add_link')" @click="openShortLink"><i class="fas fa-link"></i></b-button>
                        </div>
                    </div>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row class="mt-3">
            <b-col cols="12" class="text-end">
                <b-button class="w-100 btn-rounded px-3 btn-lg" variant="primary" block size="sm" @click="send"><i class="fas fa-paper-plane me-2"></i>{{ $t('sms.send') }}</b-button>
            </b-col>
        </b-row>
        <b-modal ref="link-modal" centered body-bg-variant="light" v-model="showLinkModal" @hide="emptyLink">
            <template #modal-title>
                {{ $t('sms.add_link') }}
            </template>
            <short-link-form v-model="link"></short-link-form>
            <template #modal-footer>
                <b-button variant="primary" class="btn-rounded px-3" size="sm" @click="addShortLink">{{ $t('sms.insert') }}</b-button>
                <b-button size="sm" class="btn-rounded px-3" @click="showLinkModal = false;">{{ $t('sms.cancel') }}</b-button>
            </template>
        </b-modal>
    </b-form>
</template>

<script>

import ShortLinkForm from "@/components/sms/short-link-form.vue";
import ShortLinkMixin from "@/mixins/shortLink.js";

export default {
    name: "single",

    mixins: [ShortLinkMixin],

    components: {ShortLinkForm},

    data: function () {
        return {
            toList: false,
            model: {
                send_date: null,
                is_sms_utf8: null
            },
            error: {
                phone_number: null,
                message: null,
                send_date: null
            },
            detailed: false,
            listOptions: [
                { text: this.$t('sms.to_target'), value: false },
                { text: this.$t('sms.to_list'), value: true }
            ],
            typeOptions: [
                { text: this.$t('sms.type.single'), value: false },
                { text: this.$t('sms.type.detailed'), value: true }
            ]
        }
    },

    computed: {
        defaultLocale: function () {
            return navigator.language
        },

        sms: function () {
            if (!this.chars) {
                return 0;
            }

            return this.chars <= 160 ? 1 : Math.ceil(this.chars / 153);
        },

        chars: function () {
            return this.model.message ? this.model.message.length : 0
        },

        phoneNumberState: function () {
            return this.error.phone_number === null
        },

        sendDateState: function () {
            return this.error.send_date === null;
        },

        messageState: function () {
            return this.error.message === null
        },

        countryCodeState: function () {
            return this.error.country_code === null
        },

        areaCodeState: function () {
            return this.error.area_code === null
        },

        numberState: function () {
            return this.error.number === null
        },

        isUtf8: function () {
            return this.$store.getters['auth/currentUser'].is_sms_utf8;
        }
    },

    methods: {
        send: function () {
            const pattern = /^\+?([\d|\s|-]*)$/;

            if (!(this.model.phone_number || (this.model.country_code && this.model.area_code && this.model.number))) {
                this.error.phone_number = this.$t('validate.required', { item: this.$t('sms.phone_number')})
            }

            if (this.model.phone_number && !pattern.test(this.model.phone_number)) {
                this.error.phone_number = this.$t('validate.invalid', { item: this.$t('sms.phone_number')})
            }

            if (!this.model.phone_number) {
                const ccPattern = /^\+?[\d\s]{1,4}/;
                const acPattern = /^[0-9]{1,2}/;
                const nPattern = /^[0-9]{1,7}/;

                if (!ccPattern.test(this.model.country_code)) {
                    this.error.country_code = this.$t('validate.invalid', { item: this.$t('sms.country_code')})
                }

                if (!acPattern.test(this.model.area_code)) {
                    this.error.area_code = this.$t('validate.invalid', { item: this.$t('sms.area_code')})
                }

                if (!nPattern.test(this.model.number)) {
                    this.error.number = this.$t('validate.invalid', { item: this.$t('sms.number')})
                }
            }

            if (!this.model.message) {
                this.error.message = this.$t('validate.required', { item: this.$t('sms.message')});
            }

            if (this.model.send_date) {
                this.model.send_date = new Date(this.model.send_date).formattedDate();
            }

            if (
                (
                    this.model.phone_number ||
                    (
                        this.model.country_code
                        && this.model.area_code
                        && this.model.number
                    )
                )
                && this.model.message
            ) {
                this.$store.dispatch('sms/send', this.model).then(response => {
                    if (response?.error !== true) {
                         // this.model = {};
                    }
                });
            }
        },

        setSendDate: function () {
            if (! this.model.send_date) {
                const now = new Date();
                this.model.send_date = now.formattedDate();
            }
        },
    },

    watch: {
        'model.phone_number': {
            deep: true,
            handler: function (value) {
                if (value) {
                    this.error.phone_number = null;
                }
                if (this.model.phone_number === '36') {
                    this.model.phone_number = '+36';
                }
            },
        },

        'model.message': {
            deep: true,
            handler: function (value) {
                if (value) {
                    this.error.message = null;
                }
            },
        },

        toList: function (boolean) {
            this.$emit('updateToList', boolean)
        },

        isUtf8: {
            immediate: true,
            handler: function (value) {
                this.model.is_sms_utf8 = value;
            }
        },
    },

    mounted() {
        this.setSendDate();
    }
}
</script>

<style lang="scss" scoped>
input::placeholder, textarea::placeholder {
    color: lightgrey;
}

::v-deep {
    .custom-control-inline {
        display: inline-flex;
        &:not(:first-of-type) {
            margin-left: 1rem;
        }
        .custom-control-label {
            margin-bottom: 0;
            margin-left: 0.25rem;
        }
    }
}
</style>
