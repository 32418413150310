<template>
    <div class="sms-to-list">
        <b-form @submit.prevent>
            <b-row>
                <b-col cols="4" class="bg-soft-light border-2 border-end border-light pt-3">
                    <b-row class="mx-0 mb-3">
                        <b-col cols="12" class="mb-3 px-0 px-md-1">
                            <b-form-group
                                id="settings-sms-coding"
                            >
                                <div class="form-check form-switch form-switch-md custom-checkbox mb-2">
                                    <label class="form-check-label" for="settings-sms-utf8">{{ $t('profile.utf8_sms_sending') }}</label>
                                    <input type="checkbox" id="settings-sms-utf8" name="settings-sms-utf8" class="form-check-input" v-model="model.is_sms_utf8" />
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-form-group :label="$t('sms.type_of_sending')">
                                <b-form-radio-group
                                    id="sending-type"
                                    v-model="toList"
                                    :options="listOptions"
                                    name="sending-type-options"
                                ></b-form-radio-group>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" v-if="false">
                            <div class="d-inline-flex align-items-center border-end border-1 border-light pe-3">
                                <label class="form-check-label me-2" for="to-list"><i class="fas fa-user"></i></label>
                                <div class="form-check form-switch form-switch-md">
                                    <input type="checkbox" id="to-list" class="form-check-input" v-model="toList" />
                                </div>
                                <label class="form-check-label" for="to-list"><i class="fas fa-users"></i></label>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row class="mx-0 mb-3">
                        <b-col cols="12">
                            <b-form-group
                                id="id-contact-list"
                                :label="$t('contact.contact_list')"
                                label-for="id-contact-list-input"
                                label-cols="12"
                                label-class="pt-0"
                                content-cols="12"
                                :state="listState"
                                :invalid-feedback="error.id_contact_list"
                                class="form-floating"
                            >
                                <b-form-select class="form-select form-select-sm" id="id-contact-list-input" v-model="model.id_contact_list" :options="contacts" value-field="m_id" text-field="formatted"></b-form-select>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row class="m-0">
                        <b-col v-if="!detailed" cols="12" class="px-0 px-md-1">
                            <b-form-group
                                id="send-date"
                                class="w-100"
                                :label="$t('sms.send_date')"
                                label-for="send-date-input"
                                label-cols="12"
                                content-cols="12"
                                :state="sendDateState"
                                :invalid-feedback="error.send_date"
                            >
                                <b-form-input id="send-date-input" type="datetime-local" required v-model="model.send_date"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols="8">
                    <b-form-group
                        id="message"
                        :label="$t('sms.message')"
                        label-for="message-input"
                        label-cols="12"
                        content-cols="12"
                        :state="messageState"
                        :invalid-feedback="error.message"
                    >
                        <template #label>
                            <label class="col-12 col-form-label" for="message-input">
                                <span class="me-3">{{ $t('sms.message') }}</span>
                                <small class="text-muted" v-if="model.message">{{ $t('sms.message_length', { sms: sms, chars: chars }) }}</small>
                            </label>
                        </template>
                        <div class="position-relative rounded overflow-hidden">
                            <b-form-textarea ref="message" id="message-input" :rows="10" v-model="model.message" :placeholder="$t('sms.placeholders.message')"></b-form-textarea>
                            <div class="bg-light position-absolute bottom-0 w-100 border p-1">
                                <b-button :disabled="disabledLink" size="sm" variant="primary" :title="$t('sms.add_link')" @click="openShortLink"><i class="fas fa-link"></i></b-button>
                            </div>
                        </div>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row class="mt-3">
                <b-col cols="12" class="text-end">
                    <b-button class="w-100 btn-rounded px-3" variant="primary" block size="sm" @click="send"><i class="fas fa-paper-plane me-2"></i>{{ $t('sms.send') }}</b-button>
                </b-col>
            </b-row>
            <b-modal ref="link-modal" centered body-bg-variant="light" v-model="showLinkModal" @hide="emptyLink">
                <template #modal-title>
                    {{ $t('sms.add_link') }}
                </template>
                <short-link-form v-model="link"></short-link-form>
                <template #modal-footer>
                    <b-button variant="primary" class="btn-rounded px-3" size="sm" @click="addShortLink">{{ $t('sms.insert') }}</b-button>
                    <b-button size="sm" class="btn-rounded px-3" @click="showLinkModal = false;">{{ $t('sms.cancel') }}</b-button>
                </template>
            </b-modal>
        </b-form>
        <h5 class="bg-light text-center mb-0 mt-4 py-2 border-bottom">{{ $t('sms.messages_sent_to_list') }}</h5>
        <div class="table-responsive mb-0">
            <b-table
                :items="messages"
                :fields="fields"
                responsive="sm"
                :per-page="paginator.perPage"
                :current-page="paginator.currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                show-empty
                :empty-text="$t('table.please_select_or_empty')"
                hover
                head-variant="light"
                :busy="loading"
                no-local-sorting
            >
                <template #table-busy>
                    <div class="text-center">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">{{ $t('table.loading') }}...</span>
                        </div>
                    </div>
                </template>
                <template #cell(is_deleted)="scope">
                    <i v-if="Number(scope.value) === 1" class="fa fa-trash-alt text-danger"></i>
                </template>
                <template #cell(actions)="scope">
                    <b-button v-if="Number(scope.item.remaining_sms) > 0 && scope.item.is_deleted != 1" v-b-tooltip.hover.top="$t('sms.revoke')" size="sm" variant="danger" @click="revoke(scope.item.id)">
                        <i class="fas fa-times-circle"></i>
                    </b-button>
                </template>
            </b-table>
        </div>
        <div class="row">
            <div class="col">
                <div
                    class="dataTables_paginate paging_simple_numbers float-end"
                >
                    <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                            v-model="paginator.currentPage"
                            :total-rows="paginator.total"
                            :per-page="paginator.perPage"
                            @change="toPage"
                        ></b-pagination>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import moment from "moment";
import Swal from "sweetalert2";
import ShortLinkForm from "@/components/sms/short-link-form.vue";
import ShortLinkMixin from "@/mixins/shortLink.js";

export default {
    name: "list",

    mixins: [ShortLinkMixin],

    components: {ShortLinkForm},

    data: function () {
        return {
            model: {},
            error: {
                message: null,
                send_date: null
            },
            sortBy: (this.paginator && this.paginator.column) || 'id',
            sortDesc: (this.paginator && this.paginator.desc) || true,
            toList: true,
            listOptions: [
                { text: this.$t('sms.to_target'), value: false },
                { text: this.$t('sms.to_list'), value: true }
            ],
        }
    },

    computed: {
        ...mapGetters({
            contacts: 'contact/selectList',
            messages: 'sms/toList',
            loading: 'sms/loading',
            paginator: 'sms/toListPager'
        }),

        sms: function () {
            if (!this.chars) {
                return 0;
            }

            return this.chars <= 160 ? 1 : Math.ceil(this.chars / 153);
        },

        chars: function () {
            return this.model.message ? this.model.message.length : 0
        },

        messageState: function () {
            return this.error.message === null
        },

        listState: function () {
            return this.error.id_contact_list === null
        },

        isUtf8: function () {
            return this.$store.getters['auth/currentUser'].is_sms_utf8;
        },

        fields: function () {
            return [
                {
                    key: "id",
                    label: '#',
                    thClass: 'gridjs-th',
                    sortable: true
                },
                {
                    key: "contact_list_name",
                    label: this.$t('contact.contact_list'),
                    thClass: 'gridjs-th',
                    sortable: true
                },
                {
                    key: "create_date",
                    label: this.$t('sms.create_date'),
                    thClass: 'gridjs-th border-left-0',
                    formatter: (value) => {
                        return moment(value).format('YYYY. MM. DD. HH:mm')
                    },
                    sortable: true
                },
                {
                    key: "deleted_date",
                    label: this.$t('sms.deleted_date'),
                    thClass: 'gridjs-th border-left-0',
                    formatter: (value) => {
                        return value ? moment(value).format('YYYY. MM. DD. HH:mm') : value
                    },
                    sortable: true
                },
                {
                    key: "is_deleted",
                    label: this.$t('sms.is_deleted'),
                    thClass: 'gridjs-th border-left-0',
                    tdClass: 'text-center',
                    sortable: true
                },
                {
                    key: "remaining_sms",
                    label: this.$t('sms.remaining_sms'),
                    thClass: 'gridjs-th border-left-0',
                    tdClass: 'text-end',
                    sortable: true
                },
                {
                    key: "sent_sms",
                    label: this.$t('sms.sent_sms'),
                    thClass: 'gridjs-th border-left-0',
                    tdClass: 'text-end',
                    sortable: true
                },
                {
                    key: "sms",
                    label: this.$t('sms.message'),
                    thClass: 'gridjs-th border-left-0',
                    sortable: true
                },
                {
                    key: "actions",
                    label: '',
                    thClass: 'gridjs-th'
                },
            ];
        },
    },

    methods: {
        send: function () {
            if (!this.model.id_contact_list) {
                this.error.id_contact_list = this.$t('validate.required', { item: this.$t('contact.contact_list')});
            }

            if (!this.model.message) {
                this.error.message = this.$t('validate.required', { item: this.$t('sms.message')});
            }

            if (this.model.send_date) {
                this.model.send_date = new Date(this.model.send_date).formattedDate();
            }

            this.$store.dispatch('sms/sendToList', this.model).then(response => {
                if (response?.error !== true) {
                    // this.model = {};
                }
            });
        },

        toPage: function (val) {
            this.page = val
            this.delayedFetch()
        },

        delayedFetch: function () {
            this.$store.dispatch('sms/fetchToListDelayed', {
                limit: this.perPage,
                offset: this.pager && this.pager.offset,
                page: this.page,
                sorting: {
                    column: this.sortBy,
                    desc: this.sortDesc
                }
            });
        },

        revoke: function (id) {
            Swal.fire({
                title: this.$t('table.confirm'),
                text: this.$t('sms.confirm_revoke'),
                icon: 'warning',
                showCancelButton: true,
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'btn btn-warning btn-md me-1',
                    cancelButton: 'btn btn-primary btn-md ms-1',
                },
                confirmButtonText: this.$t('table.yes'),
                cancelButtonText: this.$t('table.no')
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch('sms/revokeSentToList', id).then(() => {
                        Swal.fire(
                            this.$t('sms.message_revoked'),
                            this.$t('sms.selected_item_is_revoked'),
                            'success'
                        )
                    });
                }
            })
        },

        setSendDate: function () {
            if (! this.model.send_date) {
                const now = new Date();
                this.model.send_date = now.formattedDate();
            }
        },
    },

    watch: {
        toList: function (boolean) {
            this.$emit('updateToList', boolean)
        },
        sortBy: function () {
            this.delayedFetch();
        },
        sortDesc: function () {
            this.delayedFetch();
        },
        isUtf8: {
            immediate: true,
            handler: function (value) {
                this.model.is_sms_utf8 = value;
            }
        },
    },

    created() {
        this.$store.dispatch('contact/fetchSelectList')
        this.$store.dispatch('sms/fetchToList')
    },

    mounted() {
        this.setSendDate();
    }
}
</script>

<style lang="scss" scoped>
input::placeholder, textarea::placeholder {
    color: lightgrey;
}

::v-deep {
    .custom-control-inline {
        display: inline-flex;
        &:not(:first-of-type) {
            margin-left: 1rem;
        }
        .custom-control-label {
            margin-bottom: 0;
            margin-left: 0.25rem;
        }
    }
}

.form-floating {
    .form-select-sm {
        padding-top: 1rem;
    }
    ::v-deep {
        label {
            opacity: .65;
            transform: scale(.85) translateX(-0.15rem);
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            padding: 1rem 0.75rem;
            pointer-events: none;
            border: 1px solid transparent;
            transform-origin: 0 0;
            transition: opacity .1s ease-in-out,transform .1s ease-in-out;
        }
    }
}
</style>
